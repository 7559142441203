import { makeStyles } from "@mui/styles"

export default makeStyles((theme) => ({
  "card-1": {
    width: "100%",
    backgroundColor: theme.palette["green-1"],
    borderRadius: "1.5rem",
    padding: "3rem 3rem",
  },
  "card-2": {
    width: "100%",
    padding: "3rem 0",
  },
  header: {
    fontSize: "2.25rem !important",
    fontWeight: "600 !important",
  },
  description: {
    paddingTop: "1rem",
    color: theme.palette["text-2"],
    fontSize: "1.25rem !important",
  },
  "bullet-icon": {
    fontSize: "2.25rem !important",
    marginRight: "1rem",
  },
  bullet: {
    fontSize: "1.25rem !important",
    color: theme.palette["text-2"],
  },
  "text-row": {
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: "10px",
  },
  "author-name": {
    color: theme.palette["grey-1"],
    marginLeft: "3rem",
  },
  "custom-link": {
    margin: 0,
    color: "#3F51B5",
    textDecoration: "underline",
    WebkitTextDecoration: "underline",
    cursor: "pointer",
    textDecorationColor: "rgba(63, 81, 181, 0.4)",
  },
  pointTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  pointDescription: {
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  image: {
    paddingRight: "0px",
    width: "100%",
    height: "auto" /* Ensures the image maintains its aspect ratio */,
    maxWidth:
      "100%" /* Prevents it from becoming too large on bigger screens */,
    objectFit:
      "contain" /* Ensures the image fits properly within the container */,
  },
  listItem: {
    alignItems: "flex-start",
    marginBottom: theme.spacing(-2), // Adjust spacing here
  },
  listItemText: {
    "& .MuiTypography-body1": {
      fontSize: "1.25rem",
    },
    "& .MuiTypography-body2": {
      color: theme.palette.text.secondary, // Description styling
    },
  },
  listItemTextFaq: {
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
    },
    "& .MuiTypography-body2": {
      color: theme.palette.text.secondary, // Description styling
    },
  },
  gifContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))
