import React from "react"
import Slider from "react-slick"
import Box from "@mui/material/Box"

const ImageCarousel = ({ images }) => {
  // Calculate autoplay speed dynamically based on the number of images
  const autoplaySpeed = 3000 + (images.length - 1) * 500
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Loop through images
    speed: 500, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: autoplaySpeed, // Autoplay interval (ms)
    adaptiveHeight: false, // Adjust height based on image
    arrows: true, // Disable arrows (optional)
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box key={index} sx={{ textAlign: "center" }}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                maxWidth: "100%",
                maxHeight: "400px",
                objectFit: "contain",
                margin: "0 auto",
              }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  )
}

export default ImageCarousel
