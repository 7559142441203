import React, { useEffect } from "react"
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material"
import {
  Equalizer,
  Calculate,
  Adjust,
  ReceiptLong,
  Insights,
  CompareArrows,
  FormatQuote,
  Visibility,
  Search,
  TrendingUp,
  ArrowRight,
  FilterAltSharp,
  FormatListBulleted,
} from "@mui/icons-material"

import InsightsIcon from "@mui/icons-material/Insights"
import CompareArrowsIcon from "@mui/icons-material/CompareArrows"
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined"
import FAQ from "./faq"
import ReactGA from "react-ga4"
import { palette } from "../../utils/Palette"
import useStyles from "./styles"
import MetaTags from "../MetaTags"
import VideoIconButton from "./videoButton"
import ImageCarousel from "./ImageCarousel"

const HomeDashboard = () => {
  ReactGA.send({ hitType: "pageview", page: "/home" })
  const classes = useStyles()

  // for meta tags
  const title = "Landing Page - Lets Value Invest"
  const description =
    "Lets Value Invest is a value investing analysis and learning platform. It has been designed to help its users learn and apply the value investing principles and be able to analyze better, invest smarter with a long term horizon and achieve long term compounding benefits."
  const imageUrl = "https://letsvalueinvest.com/Assets/key-insight-share.png"

  useEffect(() => {}, [])

  return (
    <Box width="100%" padding={"2rem"}>
      <MetaTags
        title={title}
        description={description}
        url={window.location.href}
        imageUrl={imageUrl}
      ></MetaTags>
      {/* Green card */}
      <Grid container className={classes["card-1"]}>
        <Grid item xs={12} sm={12} md={5}>
          <Typography className={classes["header"]}>
            Learn Value Investing and <br />
            Become a Better Investor.
          </Typography>
          <Typography className={classes["description"]}>
            Focus on the core fundamentals that matter most, learn to value a
            business, and become a better investor with our value investing and
            learning platform.
          </Typography>
          <br />
          <Typography component={"span"} fontSize={"1.1rem"}>
            <FormatQuote />
            All intelligent investing is value investing – acquiring more than
            you are paying for. You must value the business in order to value
            the stock.
            <FormatQuote />
            <div className={classes["author-name"]}>
              <i> - Charlie Munger</i>
            </div>
          </Typography>
          <VideoIconButton videoId="lQCHPAr2dO0" />
        </Grid>
        <Grid item sm={0} md={1}></Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={classes["text-row"]}>
            <Equalizer className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Comprehensive 20-Year Financial Data
            </span>
          </Typography>
          <Typography className={classes["text-row"]}>
            <Calculate className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Multi-Method Intrinsic Value Estimator
            </span>
          </Typography>
          <Typography className={classes["text-row"]}>
            <InsightsIcon className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>Key insights simplified</span>
          </Typography>
          {/* <Typography paddingTop="18px" className={classes["text-row"]}>
            <PlaylistAddCheck className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Built in value investor's checklist
            </span>
          </Typography> */}
          <Typography className={classes["text-row"]}>
            <TrendingUp className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Comprehensive Analytics for Stocks in Major Indices
            </span>
          </Typography>
          <Typography className={classes["text-row"]}>
            <Visibility className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Track Your Favorite Stocks with a Personalized Watchlist
            </span>
          </Typography>
          <Typography className={classes["text-row"]}>
            <Search className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Intuitive Stock Screener for Identifying Quality, Undervalued
              Opportunities
            </span>
          </Typography>
          <Typography className={classes["text-row"]}>
            <CompareArrowsIcon className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Advanced Comparison & Visualization Tools
            </span>
          </Typography>
          <Typography className={classes["text-row"]}>
            <Adjust className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Expert Analysts targets, estimates & recommendations
            </span>
          </Typography>
          <Typography className={classes["text-row"]}>
            <ReceiptLong className={classes["bullet-icon"]} />
            <span className={classes["bullet"]}>
              Insider Ownership, Filings, Insider Activity, Dividends and more
            </span>
          </Typography>
        </Grid>
      </Grid>
      {/* Our value card */}
      <Grid container className={classes["card-2"]} justifyContent="center">
        <Grid item xs={12} sm={8} md={7} justifyContent="center">
          <Card
            className="p6"
            sx={{
              backgroundColor: palette["mint-green-bg"],
              color: "#1B1B1B",
              fontSize: "1.25rem",
            }}
          >
            <Typography>
              Dive into core investing principles, learn business valuation
              step-by-step, and elevate your skills with expert tools and
              insights tailored for value investors.
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Financials card */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <FormatListBulleted className={classes["bullet-icon"]} />
            20 Years of Financial Insights: Graphs, Tables, and Simplified
            Analysis
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="20 Years of Past Data"
                secondary="Access 20 years of financial history in yearly, TTM, and quarterly formats."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Fingraphs"
                secondary="Visualize key financial metrics through curated graphs."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Pros and Cons"
                secondary="Simplified pros and cons based on financial statements."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Summary view"
                secondary="Review major metrics across all three financial statements in one view."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Revenue Segments"
                secondary="LVI breaks down revenue by geographic regions and product segments to provide valuable insights into a company's operations."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Financials/income-statement.png`,
              `${window.location.origin}/Assets/Financials/fingraphs-1.png`,
              `${window.location.origin}/Assets/Financials/fingraphs-2.png`,
              `${window.location.origin}/Assets/Financials/summary.png`,
              `${window.location.origin}/Assets/Financials/pros-cons.png`,
              `${window.location.origin}/Assets/Financials/geographic.png`,
              `${window.location.origin}/Assets/Financials/products.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Intrinsic cal card */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <Calculate className={classes["bullet-icon"]} />
            Simple Intrinsic Value Estimator
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Adjust Assumptions for Real-Time Results"
                secondary="Experience dynamic calculations as you tweak assumptions. Instantly see how changes impact the intrinsic value."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Choose Between 3 Market Scenarios (Bull, Base, Bear)"
                secondary="Simulate the potential outcomes based on different market conditions—Bullish, Bearish, or Base—allowing you to assess a range of possibilities."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Comprehensive Range of Values and CAGRs"
                secondary="See the range of intrinsic values along with expected Compound Annual Growth Rates (CAGRs) to make better-informed investment decisions."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Analyze Over 5, 7, or 10 Years"
                secondary="Choose your desired timeframe to analyze potential growth and value, with projections spanning 5, 7, or 10 years."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Select from Multiple Valuation Methods"
                secondary="Pick from various valuation methods—such as discounted cash flow (DCF), earnings multiples, and more—to ensure the most accurate analysis."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Valuation/estimator.png`,
              `${window.location.origin}/Assets/Valuation/expected-returns.png`,
              `${window.location.origin}/Assets/Valuation/ref-data1.png`,
              `${window.location.origin}/Assets/Valuation/ref-data2.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Detailed key insights */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <Insights className={classes["bullet-icon"]} />
            Detailed Key Insights
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Logical Grouping of Financial Metrics"
                secondary="Analyze key metrics graded over 10 years to highlight strengths and weaknesses."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Pinpoint Strong and Weak Areas"
                secondary="Dive deeper into metrics that matter for informed analysis."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Overall Key Insight Score"
                secondary="A unified score reflecting the business's growth, financial health, quality, MOAT etc"
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Empowered Decision-Making"
                secondary="Gain clarity to make better, well-informed investment decisions."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Key-Insights/Simple.png`,
              `${window.location.origin}/Assets/Key-Insights/Growth.png`,
              `${window.location.origin}/Assets/Key-Insights/Management.png`,
              `${window.location.origin}/Assets/Key-Insights/Health.png`,
              `${window.location.origin}/Assets/Key-Insights/Valuation.png`,
              `${window.location.origin}/Assets/Key-Insights/MOAT.png`,
              `${window.location.origin}/Assets/Key-Insights/Quality.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Stocks Visualizer */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <CompareArrows className={classes["bullet-icon"]} />
            Powerful Stock Visualization Tools
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Historical Performance Analysis"
                secondary="Compare a company’s performance on selected metrics over time."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Multi-Company Comparisons"
                secondary="Analyze and compare multiple companies against critical financial metrics."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Extensive Historical Data"
                secondary="Leverage up to 20 years of data and over 90 financial metrics for in-depth analysis."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Valuation Insights"
                secondary="Visualize if a company is undervalued historically or relative to its peers based on key metrics."
                className={classes.listItemText}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="LVI graded stocks in major indices"
                secondary="Visualize stock ratings, valuations, moats, and past returns for informed decision-making."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Visualizer/graph.png`,
              `${window.location.origin}/Assets/Visualizer/data.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Watchlists */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <FormatListBulleted className={classes["bullet-icon"]} />
            Stay Ahead with Your Personalized Stock Watchlist
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Follow stocks you like and add them to your watchlist"
                secondary="Keep a close eye on stocks that matter most to you by adding them to a personalized list."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="View LVI analytics of your favorite stocks"
                secondary="Access detailed insights and analytics for the stocks you track including Quality, MOAT etc."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Upcoming earnings"
                secondary="Stay informed with the latest earnings dates for the companies you’re watching."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Curated news, no nonsence news"
                secondary="Get tailored news updates and market trends related to your watchlist."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Watchlists/follow.png`,
              `${window.location.origin}/Assets/Watchlists/list.png`,
              `${window.location.origin}/Assets/Watchlists/earnings.png`,
              `${window.location.origin}/Assets/Watchlists/news.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Stock screener */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <FilterAltSharp className={classes["bullet-icon"]} />
            Discover Hidden Gems with Our Intuitive Stock Screener
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Identify quality undervalued opportunities"
                secondary="Screen for stocks that combine strong fundamentals with attractive valuations."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Over 160 dynamic filters"
                secondary="Customize your search with advanced filters to find the perfect investment opportunities."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="More than 10,000 stocks to choose from"
                secondary="Explore a vast universe of stocks across sectors and industries."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Easy to use and intuitive"
                secondary="Navigate effortlessly and filter stocks with a user-friendly interface."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Screener/default.png`,
              `${window.location.origin}/Assets/Screener/criteria.png`,
              `${window.location.origin}/Assets/Screener/results.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Peers Comparison */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <CompareArrows className={classes["bullet-icon"]} />
            Comprehensive Peer Comparisons
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Side-by-Side Analysis"
                secondary="Compare a company's key insight scores directly with its peers for a holistic view."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Flexible Comparison Views"
                secondary="Choose between simple or detailed views to match your analysis needs."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Valuation and Quality Insights"
                secondary="Evaluate how a company's valuation and quality stack up against competitors."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Better Investment Decisions"
                secondary="Use peer comparisons to uncover opportunities and refine your investment strategy."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Peers/simple.png`,
              `${window.location.origin}/Assets/Peers/detailed.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Analysts price tarkets, estimates and recommendations */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <Adjust className={classes["bullet-icon"]} />
            Analysts’ Price Targets, Estimates, and Recommendations
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Price Target Forecasts"
                secondary="Analysts' price targets for the next 12-18 months to help guide your investment strategy."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Recommendation Insights"
                secondary="Recent buy, hold, or sell recommendations to understand market sentiment."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Revenue and Price Estimates"
                secondary="Projections for the company’s revenue and stock price over the next 2-4 years."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Historical Analyst Grades"
                secondary="Historical ratings provided by analysts to evaluate past performance and outlook."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Analysts/price-targets.png`,
              `${window.location.origin}/Assets/Analysts/recommendations.png`,
              `${window.location.origin}/Assets/Analysts/revenue-estimates.png`,
              `${window.location.origin}/Assets/Analysts/eps-estimates.png`,
              `${window.location.origin}/Assets/Analysts/grades.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Ownnership structure, insider trading, SEC filings and more.. */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <ReceiptLong className={classes["bullet-icon"]} />
            Ownership Structure, Insider Activity, and Key Filings
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Shareholding Patterns"
                secondary="Discover major stakeholders and institutional ownership of the company."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Insider Trading Activity"
                secondary="Gain insights into recent trades by company insiders to assess their confidence levels."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Direct Access to SEC Filings"
                secondary="Explore official documents with one-click links to the SEC.gov website."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Dividend History"
                secondary="Review the company’s historical dividend payouts to gauge its shareholder returns."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Ownerships/Shareholders.png`,
              `${window.location.origin}/Assets/Ownerships/Institutional-holders.png`,
              `${window.location.origin}/Assets/Ownerships/Mutualfund.png`,
              `${window.location.origin}/Assets/Ownerships/Insider-transactions.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      {/* Simple and intuitive calculators */}
      <Grid container className={classes["card-2"]}>
        {/* Left Side: Points and Explanation */}
        <Grid item xs={12} sm={12} lg={5} className={classes.textContainer}>
          <Typography variant="h5" className={classes["text-row"]}>
            <Calculate className={classes["bullet-icon"]} />
            Simple and Intuitive Financial Calculators
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Essential for Value Investors"
                secondary="Unlock the power of compounding with tools designed for every value investor."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="User-Friendly Calculators"
                secondary="Effortlessly calculate Compound Interest and CAGR with easy-to-use interfaces."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Growth Visualization"
                secondary="See how your investments grow over time with intuitive compound interest charts."
                className={classes.listItemText}
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="Scenario Analysis"
                secondary="Automatically assess a range of outcomes above and below your assumed interest rate."
                className={classes.listItemText}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <ArrowRight />
              </ListItemIcon>
              <ListItemText
                primary="CAGR Simplified"
                secondary="Quickly determine the compound annual growth rate (CAGR) of any investment."
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </Grid>

        {/* Right Side: GIF/Image */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={7}
          className={classes.gifContainer}
          container
        >
          <ImageCarousel
            images={[
              `${window.location.origin}/Assets/Calculator/calculator-image1.png`,
              `${window.location.origin}/Assets/Calculator/calculator-image2.png`,
              `${window.location.origin}/Assets/Calculator/calculator-image3.png`,
              `${window.location.origin}/Assets/Calculator/calculator-image4.png`,
              `${window.location.origin}/Assets/Calculator/calculator-image5.png`,
              `${window.location.origin}/Assets/Calculator/calculator-image6.png`,
            ]}
          />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      <Grid container className={classes["card-2"]}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" className={classes["text-row"]}>
            <QuestionAnswerOutlinedIcon className={classes["bullet-icon"]} />
            Frequently asked questions (FAQs)
          </Typography>{" "}
          <br />
          <FAQ />
        </Grid>
      </Grid>
      <Divider className="pl1 pr1"></Divider>
      <Box padding={"32px"} display="flex" justifyContent={"center"}>
        <Typography variant="h5" className={classes["text-row"]}>
          Master the art of analysis, make smarter investment decisions, and
          unlock the power of long-term compounding.
        </Typography>
      </Box>
    </Box>
  )
}

export default HomeDashboard
